<template>
  <div class="col-md-12">
    <div class="row" v-if="logs != ''" id="rowNameItens">
      <div class="col-md-1 d-flex justify-content-center">
        <p class="titulo_dash mb-2 nav">Código</p>
      </div>
      <div class="col-md-2 d-flex justify-content-center">
        <p class="titulo_dash mb-2 nav">Data - hora</p>
      </div>
      <div class="col-md-2 d-flex justify-content-center">
        <p class="titulo_dash mb-2 nav">Serviço</p>
      </div>
      <div class="col-md-3 d-flex justify-content-center">
        <p class="titulo_dash mb-2 nav">Método</p>
      </div>
      <div class="col-md-2 d-flex justify-content-center">
        <p class="titulo_dash mb-2 nav">Erro</p>
      </div>
      <div class="col-md-1 d-flex justify-content-center">
        <p class="titulo_dash mb-2 nav">Detalhes</p>
      </div>
    </div>
    <div v-if="logs == ''">
      <p class="my-5 text-center w-80" style="font-size:16px; color:#ffff;">
        {{ msgApresentacao }}
      </p>
    </div>
    <div v-else name="list-complete">
      <div
        id="subItensCont"
        class="col-md-12 box mb-3 list-complete-item"
        v-for="(log, index) in logs"
        :key="log.id"
      >
        <div class="row align-items-center" :style="sizeWindow > 767 ? 'height: 60px': 'height: 140px'" id="allItensCont">
          <div class="col-md-1 d-flex justify-content-center" id="txtAndValueCont">
            <p v-if="sizeWindow <= 767" class="titulo_dash mb-2 nav">Código:</p>
            <p class="numeroLog mb-0">#{{ log.id }}</p>
          </div>
          <div class="col-md-2 d-flex justify-content-center" id="txtAndValueCont">
            <p v-if="sizeWindow <= 767" class="titulo_dash mb-2 nav">Data - hora:</p>
            <p class="info-log mb-0">
              {{ getDate(log.executionTime) }}
            </p>
          </div>
          <div class="col-md-2 d-flex justify-content-center" id="txtAndValueCont">
            <p v-if="sizeWindow <= 767" class="titulo_dash mb-2 nav">Método:</p>
            <p class="info-log mb-0">
              {{ log.methodName }}
            </p>
          </div>
          <div class="col-md-3 d-flex justify-content-center" id="txtAndValueCont">
            <p v-if="sizeWindow <= 767" class="titulo_dash mb-2 nav">Serviço:</p>
            <p class="info-log mb-0">
              {{ log.serviceName }}
            </p>
          </div>
          <div class="col-md-2 ml-2 d-flex justify-content-center">
            <i
              class="fas fa-bug some log_name"
              :class="`log_name${index}`"
              v-if="log.exception"
              @click.prevent="ShowErrors(index)"
            ></i>
          </div>
          <div class="col-md-1 d-flex justify-content-center">
            <i
              class="fas fa-poll-h some"
              @click.prevent="ShowDetails(log.id)"
            ></i>
          </div>
        </div>
        <DropdownErrors
          class="dropdownerrors"
          :class="`dde${index}`"
          :errorDetails="log.exception"
        />
      </div>
      <vue-ads-pagination
        :total-items="numberOfLogs"
        :max-visible-pages="4"
        :page="page"
        @page-change="PageChange"
      >
        <template slot-scope="props">
          <div class="vue-ads-pr-2 vue-ads-leading-loose">
            Logs {{ props.start }} a {{ props.end }} no total de
            {{ props.total }}
          </div>
        </template>
        <template slot="buttons" slot-scope="props">
          <vue-ads-page-button
            v-for="(button, key) in props.buttons"
            :key="key"
            v-bind="button"
            @page-change="PageChange(button.page)"
          />
        </template>
      </vue-ads-pagination>
    </div>
    <ModalDetails :id="`modaldetails`" :detail="detail" />
  </div>
</template>

<script>
import DropdownErrors from "./detalhes/droperror.vue";
import ModalDetails from "./detalhes/modaldetalhes.vue";
import VueAdsPagination, { VueAdsPageButton } from "vue-ads-pagination";

export default {
  components: {
    DropdownErrors,
    ModalDetails,
    VueAdsPagination,
    VueAdsPageButton
  },
  props: ["logs", "numberOfLogs", "msgApresentacao", "page"],
  data() {
    return {
      detail: {
        time: "",
        value: ""
      },
      sizeWindow: 0,
    };
  },
  methods: {
    getDate(val) {
      var data = new Date(val);
      return `${data.getDate()}/${data.getMonth() +
        1}/${data.getFullYear()} - ${data.getHours()}:${
        data.getMinutes() < 10 ? "0" + data.getMinutes() : data.getMinutes()
      }`;
    },
    ShowErrors(index) {
      var show;
      if ($(".dropdownerrors").is(":visible")) {
        $(".dropdownerrors").velocity("slideUp", {
          display: "none",
          duration: 500
        });
        $(".log_name:not(:visible)").velocity("fadeIn");
      }

      if ($(`.dde${index}`).is(":visible")) {
        show = "slideUp";
      } else {
        show = "slideDown";
      }

      $(`.dde${index}`).velocity(show, {
        display: `${show == "slideUp" ? "none" : "flex"}`,
        duration: 500
      });
    },
    ShowDetails(id) {
      this.CreateDetails(id);
      $(`#modaldetails`).modal("show");
    },
    CreateDetails(id) {
      if (this.logs != "") {
        var find = this.logs.filter(log => log.id == id)[0];
        this.detail = {
          time: find.executionDuration,
          value: find.returnValue
        };
      }
    },
    PageChange(page) {
      this.$parent.search(page);
    }
  },
  mounted() {
    this.sizeWindow = $(window).width();
  }
};
</script>

<style scoped>
.dropdownerrors {
  display: none;
}
.numeroLog {
  font-size: 12px;
  font-weight: 700;
  color: #e0a638;
}
.log_name {
  opacity: 1;
}
.info-log {
  color: #fff;
  font-weight: 400;
  font-size: 12px;
}
.box {
  min-height: 60px;
  border: 1px solid #e0a638;
  background: #357eb8;
  box-shadow: 0px 3px 6px 0 rgba(68, 68, 68, 0.3);
}
.fas:hover {
  color: #fff;
}
.vue-ads-cursor-default {
  background-color: transparent;
}

.vue-ads-leading-loose {
  color: white;
}
@media screen and (max-width:767px){
  #rowNameItens{
    display: none;
  }
  #subItensCont{
    border-radius: 6px;
  }
  #txtAndValueCont{
    justify-content: space-between!important;
  }
  #allItensCont{
    padding: 8px;
  }
  .info-log{
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .numeroLog {
    font-size: 17px;
  }
  .info-log{
    font-size: 14px;
    padding-left: 3px;
  }
  .vue-ads-flex{
    flex-wrap: wrap;
  }
}
</style>
