<template> 
  <div>
    <div class="welcomeMobile" v-if="this.sizeWindow <= 767">
        <router-link to="/" class="imgWelCont">
          <img src="../assets/image/iconesMobile/grayArrow.svg" class="grayArrow">
        </router-link>
        <div class="txtWelCont">
          <p>Auditoria</p>
        </div>
    </div>
    <Auditoria />
  </div>
</template>

<script>
import "../../node_modules/@fortawesome/fontawesome-free/css/all.css";
import "../../node_modules/vue-ads-pagination/dist/vue-ads-pagination.css";

import Auditoria from "@/components/auditoria/auditoria.vue";
export default {
  components: {
    Auditoria
  },
  data() {
    return {
      page: 0,
      sizeWindow: 0,
    };
  },
  mounted() {
    this.sizeWindow = $(window).width();
  },
};
</script>

<style scoped>
@media screen and (max-width:767px){
  
  .welcomeMobile{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      background: #217ABF;
      height: 40px;
      margin-bottom: 3%;
    }
    .imgWelCont{
      width: 54%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .txtWelCont{
      width: 77%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .txtWelCont p{
      margin-bottom: 0;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
    }
    .imgWelCont img{
      padding-left: 7%;
      width: 24%;
    }
  }</style>
